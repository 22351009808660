import React from "react";

import {
  Layout,
  SEO,
  BannerStandard,
  ContentServices,
  CtaPrimary,
  ContainerBackgroundWhite,
  SliderClients,
} from "../components";
import { ContainerContent } from "../components/containers";
import { InternalLink, InternalLinkTypes } from "../components/links";
import { Colors } from "../components/constants";
import { Testimonials } from "../components/testimonials";
import { HeadingSection } from "../components/headings";

const Services = () => (
  <Layout pageLink={InternalLinkTypes.Services}>
    <SEO
      title={"Our Services"}
      description={
        "Specialising in Automation and Algorithms for Energy Markets"
      }
    />
    <BannerStandard
      title={"Our Services"}
      subTitle={"Specialising in Automation and Algorithms for Energy Markets"}
    />
    <ContainerContent>
      <ContentServices />
    </ContainerContent>
    <ContainerBackgroundWhite
      style={{
        textAlign: "center",
        padding: "0 10%",
        paddingTop: "2rem",
      }}
    >
      <HeadingSection>Our Clients</HeadingSection>
      <SliderClients rows={1} />
    </ContainerBackgroundWhite>
    <div
      style={{
        textAlign: "center",
        padding: "2rem 10%",
        background: Colors.LIGHTER_BLUE,
      }}
    >
      <Testimonials />
      <br />
      <h4
        style={{
          fontWeight: "500",
          fontSize: "1.3rem",
          lineHeight: "3rem",
          color: Colors.DARK_BLUE,
        }}
      >
        Contact us for more information or trial of our services
      </h4>
      <CtaPrimary style={{ minWidth: "160px", fontWeight: "bold" }}>
        <InternalLink url={InternalLinkTypes.Contact}>CONTACT US</InternalLink>
      </CtaPrimary>
    </div>
  </Layout>
);

export default Services;
